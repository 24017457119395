:root { 
--red_700:#cd353e; 
--blue_gray_100_01:#d9d9d9; 
--light_blue_100:#aad8f8; 
--blue_gray_100_02:#d0d3e6; 
--indigo_A200:#5e81f4; 
--amber_A400:#f6c002; 
--gray_50:#f9fbfe; 
--indigo_900_33:#0f0f9533; 
--black_900:#000000; 
--deep_orange_200_01:#feb598; 
--teal_900:#133a50; 
--blue_gray_800:#3e4958; 
--white_A700_19:#ffffff19; 
--blue_50_01:#e3efff; 
--deep_orange_200:#ffbf9f; 
--blue_gray_700:#315c85; 
--blue_gray_900_01:#1d374f; 
--blue_gray_900:#223650; 
--black_900_26:#00000026; 
--white_A700_4c:#ffffff4c; 
--gray_700:#5f5858; 
--gray_400:#bdbdbd; 
--blue_gray_100:#d3d3d3; 
--gray_500:#928888; 
--blue_gray_400:#918888; 
--indigo_50:#d9e5fa; 
--blue_gray_600:#3d618e; 
--teal_50:#d5e2f2; 
--gray_200:#ededed; 
--gray_300:#dae5e9; 
--blue_gray_800_01:#264869; 
--blue_50:#e1eafb; 
--gray_100:#f2f2f2; 
--indigo_300:#668dcb; 
--indigo_100:#b2c8e0; 
--light_blue_100_01:#aad8f7; 
--black_900_33:#00000033; 
--black_900_11:#00000011; 
--indigo_900:#0f0f95; 
--blue_200:#9dc3e6; 
--white_A700:#ffffff; 
--indigo_300_02:#78a6d2; 
--indigo_300_01:#6291be; 
--gray_700_01:#565656; 
}